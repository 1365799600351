.gallery-content {
    display: flex;
    justify-content: center;
}

.gallery-image {
    height: 45vh;
    width: 83vh;
}

.gallery-image-mobile {
    height: 60vw;
    width: 75vw;
    margin: 3vh 0;
}

.swiper-button-next:after, .swiper-button-prev:after {
    content: "";
    height: 20px;
    width: 20px;
    background-color: black;
    border-radius: 50%;
}

.mySwiper-mobile {
    display: none;
}

/* MEDIA QUERY */
@media screen and (max-width: 780px) {
    .mySwiper {
        display: none;
    }

    .mySwiper-mobile {
        display: block;
    }
}