.box-nav {
    background-color: #fff;
}

.title-nav {
    margin-top: 5vh;

    font-size: 40px;
    font-family: "trade";
}

.menu-nav {
    margin-top: 4vh;
    display: flex;
    justify-content: center;

    font-size: 20px;
    font-family: "trade";
}

.item-nav {
    margin-inline: 25px;
    background-color: #fff;
}

.sub-item-nav {
    font-size: 14px;
    font-family: "trade";
    margin-top: 15px;
}

.dot-active {
    height: 10px;
    width: 10px;
    margin: 5px auto;

    background-color: black;
    border-radius: 50%;
}

/* MEDIA QUERY */

@media screen and (max-width: 560px) {
    .menu-nav {
        font-size: 3.5vw;
    }
    
    .item-nav {
        margin-bottom: 0;
        margin-inline: 15px;
    }

    .item-has-sub {
        position: absolute;
        display: flex;
        margin-left: -8vw;
    }

    .sub-item-nav {
        margin-inline: 3px;
    }

    .dot-active {
        margin: 5px auto;
    }

    .box-nav {
        height: 23vh;
    }
}