.menu-box {
    display: flex;
    justify-content: center;
    margin: 8vh auto;

    font-size: 40px;
    font-family: "trade";
}

.menu-item {
    margin-inline: 50px;
    margin-bottom: 39px;
}

/* COCKTAILS */
.cocktails-menu {
    margin: 2vh 0 6vh 0;
    justify-content: center;
}

/* BOTTLES */
.bottles-menu {
    margin: 2vh 0 6vh 0;
    justify-content: center;
}

/* MEDIA QUERY */

@media screen and (max-width: 992px) {
    .menu-box {
        display: block;
        text-align: center;
    }

    .item {
        margin: 25px 0;
    }

    .cocktails-menu {
        margin-top: 0;
        margin-bottom: -2vh;
        justify-content: start;
    }

    .bottles-menu {
        margin-top: 0;
        margin-bottom: -5vh;
        justify-content: start;
    }
}