.footer {
    height: 12vh;
    width: 100vw;
    
    position: fixed;
    padding-top: 1.5vh;
    padding-bottom: 1vh;
    bottom: 0;

    background-color: #ffffff;
    font-family: "trade";
}

.img-dots {
    width: 380px;
    margin-bottom: 1vh;
}

.text-footer {
    text-align: center;   
    font-size: 12px;
}

/* MEDIA QUERY */
@media screen and (max-width: 420px) {
    .img-dots {
        width: 70vw;
        margin-bottom: 1vh;
    }
}