.contact-content {
    margin-bottom: 6vh;
}

.title-text-content {
    text-decoration: underline;
    font-size: 13px;
}

.text-contact {
    font-size: 10px;
}

.text-content {
    width: 10vw;
    margin-inline: 4.5vw;
    font-family: "trade";
}

.form-contact-content {
    display: flex;
    justify-content: center;

    margin-top: 5vh;
}

.form-reservation {
    margin: auto 0;
    width: 40vh;
    padding-inline: 10px;
    font-family: "trade";
}

.title-form {
    text-align: center;
    font-size: 20px;
}

.form-input-reservation {
    margin-top: 2vh;

    border: none;
}

.form-input-reservation:focus {
    outline: none !important;
    box-shadow: none;
}

.btn-reservation {
    margin: 20px;
    display: flex;
    justify-content: center;
}

/* MEDIA QUERY */
@media screen and (min-width: 450px) {
    .text-contact {
        font-size: 13px;
    }
}

@media screen and (max-width: 1010px) {
    .text-content {
        width: 25vw;
        margin-inline: 4vw;
        font-size: 12.5px;
        font-family: "trade";
    }

    .form-contact-content {
        display: block;
        margin-top: 3vh;
        margin-inline: auto ;
        width: fit-content;
    }

    .form-reservation {
        margin-top: 5vh;
    }
}