.about-content {
    padding-inline: 19vw;

    font-size: 15px;
    font-family: "trade";
    line-height: 35px;
}

/* MEDIA QUERY */

@media screen and (max-width: 992px) {
    .about-content {
        padding-inline: 10vw;
    }
}