body {
  height: 10vh;
  width: 100vw;
  
  margin: 0;
  font-family: "trade";
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box {
  padding-bottom: 15vh;
  max-height: 70vh;
  height: 65vh;
  overflow: auto;
}

@font-face {
  font-family: "trade";
  src: local("TradeGothicNextLTPro-Bd"),
    url("../public/assets/fonts/trade-gothic/TradeGothicNextLTPro-BdCn.otf") format("opentype");
}
