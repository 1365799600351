.box-home {
    height: 88vh;
    max-height: 88vh;
    overflow: auto;
}

.title-home {
    margin-top: 20vh;

    font-size: 40px;
    font-family: "trade";
}

.menu-home {
    margin-top: 15vh;
    display: flex;
    justify-content: center;

    font-size: 20px;
    font-family: "trade";
}

.item {
    margin-inline: 25px;
}

/* MEDIA QUERY */

@media screen and (max-width: 992px) {
    .title-home {
        margin-top: 15vh;
    }

    .menu-home {
        display: block;
        margin-top: 10vh;
        text-align: center;
    }

    .item {
        margin: 6vh 0;
    }
}